.progress {
  display: flex;
  overflow: visible;
  margin-bottom: 0;

  &-bar {
    & {
      position: relative;
      float: none;
    }
    &:first-child {
      border-radius: 20px 0 0 20px;
    }
    &:last-child {
      border-radius: 0 20px 20px 0;
    }
  }

  &-label {
    font-size: 10px;
    position: absolute;
    left: 50%;
    bottom: 100%;
    pointer-events: none;
    line-height: 2;
    font-size: map-get($fontSize, xs);
    color: #b9b9b9;
    transform: translateX(-50%);
  }
}
