// Colors
$blue: #0955B5;
$blue_light: #0A89BF;
$green_main: #00A2A8;
$green_light: #0ABF92;
$green: #09B55A;
$white: #ffffff;
$muted_color: #f2edf1;

  // alerts
$warning_color: #be570c;

$fontSize: (
  xs     : 10px,
  sm     : 12px,
  normal : 14px,
  md     : 16px,
  xmd    : 18px,
  lg     : 20px,
  xlg    : 30px,
  hg     : 50px,
);

$codeasy-material: (
  50: #e1f5fb,
  100: #b4e5f4,
  200: #84d4ed,
  300: #58c3e6,
  400: #39b6e3,
  500: #1ea9e0,
  600: #169bd2,
  700: #0a89bf,
  800: #0a78ab,
  900: #00588a,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50:  rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);
