@import "../theme/variables";

// Material toolbar
.mat-toolbar-multiple-rows {
  min-height: 50px !important;
}
.mat-toolbar-row, .mat-toolbar-single-row {
  height: 50px !important;
}
/**
 * Toaster
 */
.ngx-toastr {
  text-align: left;
  width: auto !important;
  max-width: 500px;
  padding: 15px 20px !important;
  box-shadow: none !important;
  border-left: 10px solid;
  background: rgba(0, 0, 0, .8);
  border-radius: 5px !important;

  &.toast-error {
    border-color: #B40000;
  }

  &.toast-success {
    border-color: $green;
  }

  &.toast-info {
    border-color: $green_main;
  }

  .toast-message {
    padding-right: 20px;
  }

  .toast-close-button {
    height: 0;
    outline: none !important;

    &:hover {
      color: white;
    }
  }
}
