@import "../theme/variables";

body {
  &.mat-typography {
    margin: 0;
  }
}

html,
body,
[ng-view],
.page-wrapper {
  min-height: 100vh;
}
.full-width { width: 100%; }

a.link {
  color: $blue_light;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.warning {
  color: $warning_color;
}
div.warning {
  vertical-align: middle;
  span {
    vertical-align: super;
  }
}
